import { LOCALES } from '../../../config/next/languages';

const { npm_lifecycle_event: npmLifecycleEvent } = process.env;

export const NEXT_LOCALE_TO_PRISMIC_LOCALE_MAPPING: Partial<
  MappedObject<string>
> = {
  [LOCALES.ca]: 'en-ca',
  [LOCALES.fr]: 'fr-ca',
  [LOCALES.au]: 'en-au',
  [LOCALES.nz]: 'en-nz',
};

export const PRISMIC_PAGES_CACHE_KEY = 'PRISMIC_PAGES';

export const PRISMIC_BLOG_POSTS_CACHE_KEY = 'PRISMIC_BLOG_POSTS';

export const PRISMIC_CONFIG_CACHE_KEY = 'PRISMIC_CONFIG_CACHE_KEY';

const PRISMIC_COINS_CONFIG_CACHE_KEY = 'PRISMIC_COINS_CONFIG_CACHE_KEY';

export const getPrismicCoinsConfigCacheKey = (locale: string): string =>
  `${PRISMIC_COINS_CONFIG_CACHE_KEY}_${locale}`;

export const PRISMIC_CACHE_OPTIONS = {
  maxAge: npmLifecycleEvent !== 'build' ? 0 : 10 * 60 * 1000,
};

export const HEADER_PROPS_PAGE_PROPS_KEY = 'HEADER_NAV_ITEMS';
export const FOOTER_PROPS_PAGE_PROPS_KEY = 'FOOTER_PROPS';
export const CITIES_PROPS_PAGE_PROPS_KEY = 'CITIES_PROPS';
export const COINS_CONFIG_PROPS_PAGE_PROPS_KEY = 'COINS_CONFIG_PROPS';

export const MAX_EXCERPT_LENGTH = 300;
