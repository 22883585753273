import styled from '@emotion/styled';
import media from 'setup/MediaQueries';

export const StyledContentWrapper = styled.div`
  max-width: 100vw;
  margin: 0 auto;
  word-break: break-word;

  @media ${media.phone} {
    max-width: ${({ theme }) => theme.sizes.inner};
    width: 100vw;
  }
`;
