import { addLocale } from 'next/dist/shared/lib/router/router';
import { QueryClient, QueryFunction } from 'react-query';
import type { Organization, WithContext } from 'schema-dts';
import urlJoin from 'url-join';
import { getConfig } from 'utils/config';

import { makeDefaultQueryFunction } from 'utils/reactQuery';

export const makeReactQueryClient = (locale: string): QueryClient =>
  new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        queryFn: makeDefaultQueryFunction(locale) as QueryFunction,
        retry: false,
      },
    },
  });

export const makeOrganizationSchema = (
  locale: string,
  defaultLocale: string,
): WithContext<Organization> => {
  const { appDomain } = getConfig().publicRuntimeConfig;
  const mainUrl = urlJoin(appDomain, addLocale('/', locale, defaultLocale));

  return {
    '@context': 'https://schema.org',
    '@type': 'Corporation',
    name: 'Localcoin',
    url: mainUrl,
    logo: urlJoin(appDomain, 'assets/images/logo.png'),
    sameAs: [
      'https://www.facebook.com/LocalcoinATM',
      'https://twitter.com/localcoinatm',
      'https://www.linkedin.com/company/localcoinatm/',
      'https://www.instagram.com/localcoinatm/',
      mainUrl,
    ],
  };
};
