import { ThemeUICSSObject } from 'theme-ui';

export default {
  justifyContent: 'center',
  textAlign: 'center' as ThemeUICSSObject['textAlign'],
  flexShrink: 0,
  flexDirection: 'row',
  color: 'black',
  list: {
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  item: {
    a: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: 'white',
      p: '0.3125rem 0.625rem',
      fontFamily: 'secondary',
      fontWeight: 'regular',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  image: {
    flexShrink: 0,
    width: '1.125rem',
    margin: '0 1.125rem 0 0.25rem',
  },
  dropdown: {
    position: 'relative' as ThemeUICSSObject['position'],
    alignItems: 'center',
    border: '1px solid',
    borderColor: 'silver',
    borderRadius: '0.75rem',
    padding: '0.75rem',
    a: {
      color: 'black',
      display: 'flex',
      alignItems: 'center',
      variant: 'text.typo10',
      lineHeight: 1,
    },
    buttonLink: {
      p: 0,
      lineHeight: 1,
      color: 'black',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: 'transparent',
      variant: 'text.typo10',
      '&:hover': {
        color: 'texasRose',
      },
    },
  },
  dropdownWrapper: {
    zIndex: 'selectLocation',
  },
  dropdownList: {
    width: '100%',
    py: '0.375rem',
    boxShadow: 'selectLocationDropdown',
    border: '1px solid',
    borderColor: 'silver',
    borderRadius: '0.75rem',
    borderTopLeftRadius: '0',
    borderTopRightRadius: '0',
    borderTop: 'none',
  },
  dropdownItem: {
    a: {
      p: '0.375rem 0.75rem',
    },
  },
};
