import React, { Fragment } from 'react';
import Head from 'next/head';

import Footer from 'components/organisms/Footer';
import Header from 'components/organisms/Header';
import { demoSelectLocationItems } from 'components/molecules/SelectLocation/consts';
import Banner from 'components/molecules/Banner';
import { useAlertsContext } from 'contexts/AlertsContext';
import RichTextRenderer from 'components/PageSectionsRenderer/RichTextRenderer';
import { BannerVariant } from 'components/molecules/Banner/consts';
import { LayoutProps } from './types';
import {
  StyledAlertBannerContent,
  StyledLayoutMain,
  StyledLayoutWrapper,
  StyledNewsletterBannerContent,
  StyledNewsletterBannerPill,
  StyledNewsletterContentWrapper,
} from './styles';
import { IconInfo } from '../Icons';

const Layout: React.FC<LayoutProps> = ({
  children,
  navItems,
  footerProps,
  newsletterBannerContent,
  newsletterPillContent,
  hostAtmLinkLabel,
  hostAtmLink,
  isNavigationHidden,
}) => {
  const { alerts } = useAlertsContext();

  return (
    <Fragment>
      <Head>
        <title>Localcoin | Bitcoin ATM</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <StyledLayoutWrapper>
        {alerts.map(({ content }, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Banner key={index} variant={BannerVariant.Info} withoutCloseButton>
            <StyledAlertBannerContent>
              <IconInfo />

              <RichTextRenderer {...{ content }} />
            </StyledAlertBannerContent>
          </Banner>
        ))}

        {!isNavigationHidden && (
          <Header
            {...{
              navItems,
              newsletterBannerContent,
              newsletterPillContent,
              hostAtmLinkLabel,
              hostAtmLink,
            }}
          />
        )}

        <StyledLayoutMain as="main">
          {newsletterBannerContent && (
            <Banner>
              <StyledNewsletterBannerContent withPill={!!newsletterPillContent}>
                {newsletterPillContent && (
                  <StyledNewsletterBannerPill>
                    {newsletterPillContent}
                  </StyledNewsletterBannerPill>
                )}

                <StyledNewsletterContentWrapper>
                  {newsletterBannerContent}
                </StyledNewsletterContentWrapper>
              </StyledNewsletterBannerContent>
            </Banner>
          )}

          {children}
        </StyledLayoutMain>
        {!isNavigationHidden && (
          <Footer {...footerProps} items={demoSelectLocationItems} />
        )}
      </StyledLayoutWrapper>
    </Fragment>
  );
};

export default Layout;
