export default {
  height: '100%',
  loader: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrapper: {
    height: '100%',
    '> div': {
      height: '100%',
    },
    '.buttons-wrapper': {
      display: 'flex',
    },
    '.button': {
      m: '0.1875rem 0',
      '&:not(:last-of-type)': {
        marginRight: '0.25rem',
      },
      display: 'inline-block',
      borderRadius: 'small',
      border: '2px solid',
      fontSize: '0.875rem',
      fontWeight: 'semiBold',
      fontFamily: 'secondary',
      p: '0.5rem 1rem',
    },
    '.button--outlined-colored': {
      variant: 'buttons.modes.outlined.modeTypes.colored',
    },
    '.button--outlined-dark': {
      variant: 'buttons.modes.outlined.modeTypes.dark',
    },
    '.button-dark': {
      variant: 'buttons.modes.primary.modeTypes.darkBordered',
    },
    '.tooltip-name': {
      fontSize: '1.125rem',
    },
    '.badges-list': {
      display: 'block',
      '& > li[aria-hidden]': {
        display: 'block',
      },
    },
    '.badges-list--list-item': {
      display: 'inline-block',
    },
    '.badge': {
      variant: 'currencyLabel.default',
      mt: '0.125rem',
      mb: '0.25rem',
      mr: '0.5rem',
    },
    '.gm-style .gm-style-iw-c': {
      pb: '0.5rem !important',
      maxWidth: ['calc(100vw - 5rem) !important', '100%'],
    },
    '.badges': {
      minHeight: ['3.75rem', '1.875rem', 'auto'],
    },
    '.loader': {
      minHeight: ['3.75rem', '1.875rem', 'auto'],
      color: 'fuelYellow',
    },
  },
};
