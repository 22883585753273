export default {
  stepTitle: {
    mt: '0.5rem',
    display: 'block',
    fontWeight: 'semiBold',
    fontSize: '1rem',
    textAlign: 'center',
  },
  stepDescription: {
    fontSize: '0.875rem',
    textAlign: 'center',
  },
  image: {
    borderRadius: '100%',
    overflow: 'hidden',
  },
};
