export default {
  gridTemplateColumns: ['1fr', '', '1fr 25rem'],
  gridColumnGap: '1.5rem',
  alignItems: 'center',
  heading: {
    backgroundColor: 'fuelYellow',
    color: 'white',
    fontWeight: 'bold',
    padding: '0.25rem 0.5rem',
    fontSize: '0.875rem',
    borderRadius: '.125rem',
    width: '100%',
  },
  map: {
    justifySelf: ['', '', 'end'],
    maxWidth: '20rem',
    mx: ['auto', '', 0],
    width: '100%',
    a: {
      display: 'flex',
      boxShadow: 'locationDetails',
    },
  },
  p: {
    fontSize: '1.125rem',
    display: 'block',
    marginBottom: '1rem',
  },
};
