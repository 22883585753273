import { GetAlertsResponse } from 'components/pages/api/alerts/types';
import { useRouter } from 'next/router';
import React, { useContext, useMemo } from 'react';
import { useQuery } from 'react-query';
import { ALERTS_REFETCH_INTERVAL } from './consts';
import { AlertsContextValue } from './types';
import { makeAlertsUrl } from './utils';

const AlertsContext = React.createContext<AlertsContextValue>({
  alerts: [],
});

export const useAlertsContext = (): AlertsContextValue =>
  useContext(AlertsContext);

const AlertsContextProvider: React.FC = ({ children }) => {
  const { locale } = useRouter();

  const { data } = useQuery<UseQueryResponse<GetAlertsResponse>>(
    makeAlertsUrl(locale),
    {
      refetchInterval: ALERTS_REFETCH_INTERVAL,
    },
  );

  const value = useMemo(() => {
    const { response } = data ?? {};
    const { alerts = [] } = response ?? {};

    return { alerts };
  }, [data]);

  return (
    <AlertsContext.Provider {...{ value }}>{children}</AlertsContext.Provider>
  );
};

export default AlertsContextProvider;
