import { ThemeUICSSObject } from 'theme-ui';
import search from './FindATMSearch/theme';
import { ATMS_LIST_HEIGHT } from './StoresListing/consts';
import stores from './StoresListing/theme';

export default {
  border: '1px solid',
  borderColor: 'mercury',
  height: ['', '', '38.375rem'],
  flexDirection: ['column', '', 'row'],
  title: {
    fontSize: '1.375rem',
    letterSpacing: '-0.03em',
    fontFamily: 'secondary',
    mb: '0.5rem',
    '.fonts-loading &': {
      letterSpacing: '0',
    },
  },
  header: {
    p: '1rem',
    height: ['', '', '10rem'],
  },
  results: {
    height: `calc(${ATMS_LIST_HEIGHT}px + 2.25rem)`,
    borderTop: '1px solid',
    borderColor: 'mercury',
  },
  resultsFound: {
    padding: '0.5rem 1rem',
    borderBottom: '1px solid',
    borderColor: 'mercury',
    textTransform: 'uppercase' as ThemeUICSSObject['textTransform'],
    variant: 'text.typo10',
  },
  details: {
    width: ['100%', '', '45%', '35%'],
  },
  tools: {
    mt: '1rem',
    mb: '0.5rem',
    alignItems: 'center',
    gridTemplateColumns: ['1fr', '1fr 9.625rem'],
  },
  enterYourSearch: {
    my: '3.125rem',
    mx: '1rem',
    color: 'lochmara',
    fontStyle: 'italic',
    fontWeight: 'semiBold',
    textAlign: 'center' as ThemeUICSSObject['textAlign'],
  },
  map: {
    width: ['100%', '', '55%', '65%'],
    height: ['37.5rem', 'auto'],
  },
  search,
  stores,
};
