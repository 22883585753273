/** @jsxImportSource theme-ui */
import React, { Fragment } from 'react';
import { Image as ThemedImage } from 'theme-ui';
import NextImage from 'next/image';

import { ImageProps } from './types';

const Image: React.FC<ImageProps> = ({
  src,
  alt = 'Photo',
  width,
  height,
  variant = 'base',
  priority,
  layout = 'intrinsic',
  lazy = true,
  ...props
}) =>
  width && height && lazy ? (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <NextImage
      {...{ src, alt, width, height, priority, layout, ...props }}
      // after one of releases with no changes to this particular piece of code, next/vercel started to throw an error:
      // INVALID_IMAGE_OPTIMIZE_REQUEST, to fix this I added "unoptimized" prop to the NextImage component, then:
      // then another bad requests came in, this time it was 400, and the error was "Invalid URL", after some research
      // there were a few issues regarding this, fix from this one worked https://github.com/vercel/next.js/discussions/62676
      loader={({ src: loaderSrc }) => loaderSrc}
    />
  ) : (
    <Fragment>
      {layout === 'fill' && lazy ? (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <NextImage
          {...{ src, alt, priority, layout, ...props }}
          loader={({ src: loaderSrc }) => loaderSrc}
        />
      ) : (
        <ThemedImage {...{ src, alt, variant, width, height, ...props }} />
      )}
    </Fragment>
  );

export default Image;
