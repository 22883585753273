import routes from 'setup/consts/routes';
import { LOCALES } from '../../../../config/next/languages';
import { SelectLocationItem } from './types';

export const demoSelectLocationItems: SelectLocationItem[] = [
  {
    key: LOCALES.au,
    label: 'English (AU)',
    href: routes.home,
    src: '/assets/images/australia-flag.png',
    alt: 'English (AU)',
    locale: LOCALES.au,
  },
  {
    key: LOCALES.nz,
    label: 'English (NZ)',
    href: routes.home,
    src: '/assets/images/australia-flag.png',
    alt: 'English (AU)',
    locale: LOCALES.nz, // NZ: change to nz flag
  },
  {
    key: LOCALES.ca,
    label: 'English (CA)',
    href: routes.home,
    src: '/assets/images/canadian-flag.png',
    alt: 'English (CA)',
    locale: LOCALES.ca,
  },
  {
    key: LOCALES.fr,
    label: 'French (QC)',
    href: routes.home,
    src: '/assets/images/quebec-flag.png',
    alt: 'French (QC)',
    locale: LOCALES.fr,
  },
];
