export default {
  title: {
    variant: 'text.heading1',
    mb: '1.5rem',
    textAlign: ['center', 'left'],
  },
  description: {
    variant: 'text.heading6',
    mb: '1.5rem',
    display: ['none', 'block'],
  },
  details: {
    gridRowGap: '1.5rem',
    gridColumnGap: '0.25rem',
    gridTemplateColumns: '2rem 1fr',
    variant: 'text.typo4',
    mb: '1.5rem',
    strong: {
      flexShrink: 0,
      mr: '0.5rem',
      fontWeight: 'semiBold',
    },
  },
  icon: {
    mt: '0.25rem',
    color: 'fuelYellow',
    svg: {
      fontSize: '1.25rem',
    },
  },
  openHours: {
    gridRowGap: ['0.125rem', '', '1rem'],
    gridColumnGap: '0.25rem',
    gridTemplateColumns: ['1fr', '', 'auto 1fr'],
  },
  buySell: {
    gridRowGap: ['0.125rem', '', '0.25rem'],
    gridColumnGap: '0.25rem',
    gridTemplateColumns: ['1fr', '', '2.625rem 1fr'],
  },
  actions: {
    flexDirection: ['column', 'row'],
    flexWrap: 'wrap',
    gap: '1rem',
    'button, a': {
      padding: '0.5rem 2rem',
    },
  },
};
