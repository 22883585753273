import styled from '@emotion/styled';
import media from 'setup/MediaQueries';
import { Box, Flex } from 'theme-ui';
import { StyledNewsletterBannerContentProps } from './types';

export const StyledLayoutWrapper = styled(Flex)`
  min-height: 100vh;
  flex-direction: column;
`;

export const StyledLayoutMain = styled(Flex)`
  flex-grow: 1;
  flex-direction: column;
`;

export const StyledAlertBannerContent = styled(Flex)`
  align-items: center;
  padding: 0 1.25rem;

  svg {
    margin-right: 0.5rem;
    flex-shrink: 0;
  }

  @media ${media.phone} {
    padding: 0;
    justify-content: center;
  }
`;

export const StyledNewsletterBannerPill = styled(Box)`
  background-color: ${({ theme }) => theme.colors.buttercup};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 1.5rem;
  padding: 0.125rem 0.625rem;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  margin-right: 0.75rem;
  flex-shrink: 0;
`;

export const StyledNewsletterBannerContent = styled(
  Box,
)<StyledNewsletterBannerContentProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.saffronMango};
  padding: ${({ withPill }) =>
    withPill ? '0.375rem 0.75rem 0.375rem 1.25rem' : '0.375rem 1.25rem'};
  width: 100%;

  @media ${media.phone} {
    border-radius: 1.5rem;
    padding: ${({ withPill }) =>
      withPill ? '0.375rem 0.75rem 0.375rem 0.375rem' : '0.375rem 0.75rem'};
    width: auto;
  }
`;

export const StyledNewsletterContentWrapper = styled.div`
  padding-right: 1rem;
`;
