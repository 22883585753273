import { ThemeUICSSObject } from 'theme-ui';

export default {
  variant: 'text.text',
  'p + p, p + h2, p + h3, p + h4': {
    mt: '1rem',
  },
  'p + ul, p + ol': {
    mt: '0.625rem',
  },
  'p:empty': {
    mb: '1rem',
  },
  h1: {
    variant: 'text.heading3',
    fontWeight: 'normal',
    mb: '0.625rem',
  },
  h2: {
    variant: 'text.heading4',
    mb: '0.625rem',
  },
  h3: {
    variant: 'text.heading5',
    mb: '0.625rem',
  },
  h4: {
    variant: 'text.heading6',
    mb: '0.625rem',
  },
  'strong, bold': {
    fontWeight: 'semiBold',
  },
  em: {
    fontStyle: 'italic',
  },
  i: {
    fontStyle: 'italic',
  },
  a: {
    textDecoration: 'underline',
    color: 'fuelYellow',
    '&:hover': {
      color: 'texasRose',
    },
  },
  'ul, ol': {
    pl: '1.5rem',
    mb: '1rem',
  },
  ul: {
    listStyleType: 'disc',
  },
  ol: {
    listStyleType: 'decimal',
  },
  'ol[type="a"]': {
    listStyle: 'lower-alpha',
  },
  li: {
    mb: '0.5rem',
  },
  img: {
    display: 'block',
    my: '1rem',
  },
  '.table-wrapper': {
    overflowX: ['auto', 'unset'],
    WebkitOverflowScrolling: 'touch',
    scrollBehavior: 'smooth',
    mb: '2rem',
  },
  table: {
    width: ['', '100%'],
    borderCollapse: 'collapse',
  },
  th: {
    textAlign: 'left' as ThemeUICSSObject['textAlign'],
    color: 'white',
    backgroundColor: 'fuelYellow',
    variant: 'text.text',
    p: '0.5rem',
  },
  'tr> td:first-child': {
    backgroundColor: 'alabaster',
  },
  td: {
    verticalAlign: 'middle',
    border: '1px solid',
    borderColor: 'mercury',
    p: '0.5rem',
    variant: 'text.text',
    strong: {
      fontWeight: 'semiBold',
    },
    a: {
      textDecoration: 'none',
      fontWeight: 'semiBold',
    },
  },
  '.center': {
    textAlign: 'center' as ThemeUICSSObject['textAlign'],
    display: 'block',
  },
  '.align-right': {
    textAlign: 'right' as ThemeUICSSObject['textAlign'],
    display: 'block',
  },
};
