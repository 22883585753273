import urljoin from 'url-join';
import { getConfig } from 'utils/config';
import { LOCALES } from '../../../config/next/languages';

const { sellWidgetBasePath } = getConfig().publicRuntimeConfig;

const routes = {
  home: '/',
  howToBuyBitcoin: '/how-to-buy-bitcoin/',
  howToSellBitcoin: '/how-to-sell-bitcoin/',
  hostBitcoinAtm: '/host-bitcoin-atm/',
  bitcoinATM: '/bitcoin-atm/',
  bitcoinATMToronto: '/bitcoin-atm-toronto/',
  about: '/about/',
  careers: '/careers/',
  press: '/press/',
  partnerships: '/partnerships/',
  compliancePolicy: '/compliance-policy/',
  privacyPolicy: '/privacy-policy/',
  termsService: (locale: string): string =>
    ({
      [LOCALES.fr]: `/${LOCALES.fr}/conditions-dutilisation/`,
    }[locale] || '/terms-service/'),
  blog: '/blog/',
  contact: (locale = ''): string => `/${locale}/contact/`,
  howToBuyEthereum: '/how-to-buy-ethereum/',
  howToBuyLitecoin: '/how-to-buy-litecoin/',
  eTransferBuy: '/e-transfer-buy/',
  flexepin: '/flexepin-v3/',
  verifyTransaction: '/verify-transaction/',
  fees: '/fees/',
  faq: '/faq/',
  buyCreditCard: '/buy-credit-card/',
  state: (stateSlug: string): string => `/bitcoin-atm/${stateSlug}/`,
  city: (stateSlug: string, citySlug: string): string =>
    `/bitcoin-atm/${stateSlug}/${citySlug}/`,
  atmDetails: (stateSlug: string, citySlug: string, atmSlug: string): string =>
    `/bitcoin-atm/${stateSlug}/${citySlug}/${atmSlug}/`,
  blogPage: (page: number): string => `/blog/page/${page}/`,
  blogDetails: (slug: string): string => urljoin('/blog/', slug, '/'),
  blogCategory: (slug: string): string => urljoin('/blog/category/', slug, '/'),
  sellForm: (id: string): string => `${sellWidgetBasePath}/${id}/`,
  sellOrder: `${sellWidgetBasePath}/order/`,
  survey: '/survey/',
  surveyCompleted: '/survey/completed/',
  surveyDirectFeedback: '/survey/completed/direct-feedback',
};

export default routes;
