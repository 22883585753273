export const scrollToHash = (hash: string, smooth = true): void => {
  const element = document.querySelector(`[data-hashscroll-id="${hash}"]`);

  if (!element) {
    return;
  }

  element.scrollIntoView(
    smooth
      ? {
          behavior: 'smooth',
        }
      : undefined,
  );
};

export const copyTextToClipboard = (text: string): void => {
  if ('clipboard' in navigator) {
    navigator.clipboard.writeText(text);
  } else {
    document.execCommand('copy', true, text);
  }
};

export const scrollTo = (
  x: number,
  y: number,
  behavior: ScrollBehavior = 'smooth',
): void => {
  if (typeof window === 'undefined') {
    return;
  }

  window.scrollTo({
    behavior,
    left: x,
    top: y,
  });
};

export const goToExternalUrl = (url: string, openInNewTab = true): void => {
  const anchor = document.createElement('a');

  document.body.appendChild(anchor);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  anchor.style = 'display: none';
  anchor.href = url;

  if (openInNewTab) {
    anchor.target = '_blank';
  }

  anchor.click();
  document.body.removeChild(anchor);
};

let domParserInstance: DOMParser;

export const getDomParserInstnce = (): DOMParser => {
  if (!domParserInstance) {
    domParserInstance = new DOMParser();
  }
  return domParserInstance;
};

export const clearHTMLMarkupFromString = (stringWithHTML: string): string => {
  if (!stringWithHTML) {
    return '';
  }

  const containerId = Date.now().toString();
  const parsedHtml = getDomParserInstnce().parseFromString(
    `<div id="${containerId}">${stringWithHTML}</div>`,
    'text/html',
  );

  return (parsedHtml.getElementById(containerId)?.textContent || '').replace(
    /\r/g,
    '',
  );
};
