import { useRouter } from 'next/router';
import { ensureTrailingSlash } from 'utils/lodash/client';

export const useIsUrlActive = (
  urlOrUrls: string | string[],
  exact = true,
): boolean => {
  const urlsToCheckList = (
    Array.isArray(urlOrUrls) ? urlOrUrls : [urlOrUrls]
  ).map((url) => ensureTrailingSlash(url));
  const router = useRouter();

  return exact
    ? urlsToCheckList.includes(router.asPath)
    : urlsToCheckList.some((url) => router.asPath.startsWith(url));
};
