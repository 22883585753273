import 'styles/reset.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'setup/extensions';
import smoothscroll from 'smoothscroll-polyfill';
import { isServer } from 'utils/nextjs';

//////////////////////
///////////////////////////////////

/////////////////////////////
//////////

if (!isServer()) {
  smoothscroll.polyfill();
}

export { default } from 'components/pages/_app';
