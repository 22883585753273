import { ThemeUICSSObject } from 'theme-ui';
import mobileButton from './partials/MobileButton/theme';
import nav from './partials/Nav/theme';
import subMenu from './partials/SubMenu/theme';

export default {
  content: {
    position: 'relative' as ThemeUICSSObject['position'],
    zIndex: 'header',
  },
  main: {
    position: 'relative' as ThemeUICSSObject['position'],
    zIndex: 'header',
    flexDirection: ['column', '', 'row'],
    alignItems: ['flex-start', '', 'center'],
    justifyContent: 'space-between',
    pt: ['0.75rem', '', '0.5rem'],
    pb: ['', '', '0.5rem'],
    minHeight: ['3.75rem', '', 'auto'],
  },
  logo: {
    pb: ['0.75rem', '', 0],
    flexShrink: 0,
    width: '14.25rem',
    a: {
      display: 'flex',
      color: 'thunder',
    },
    svg: {
      fontSize: '2.1875rem',
    },
  },
  navWrapper: {
    width: '100%',
    display: ['none', '', 'block'],
  },
  mobileNavWrapper: {
    width: '100%',
    display: ['', '', 'none'],
  },
  mobileButton,
  nav,
  subMenu,
};
