import { css } from '@emotion/react';
import { Theme } from 'setup/theme';

interface TransitionMixinProps {
  timing?: keyof Theme['transitions'];
  property?: string;
}

export const transition =
  ({ timing = 'default', property = 'all' }: TransitionMixinProps = {}) =>
  ({ theme: { transitions } }: { theme: Theme }): ReturnType<typeof css> =>
    css`
      transition: ${property} ${transitions[timing].transition};
    `;
