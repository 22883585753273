import { ThemeUICSSObject } from 'theme-ui';
import { BannerVariant } from './consts';

export default {
  base: {
    position: 'relative' as ThemeUICSSObject['position'],
    width: '100%',
    p: ['0.5rem 0', '0.625rem 0'],
    fontFamily: 'inherit',
    height: 'auto',
  },
  content: {
    textAlign: ['left', 'center', 'center'],
    h2: {
      pr: ['0.5rem', 0],
      height: '100%',
      fontSize: ['1rem', '1.125rem'],
      fontWeight: 'regular',
    },
    strong: {
      fontWeight: 'semiBold',
    },
    '.underline': {
      variant: 'transitions.default',
      textDecoration: 'underline',
      '&:hover': {
        textDecoration: 'none',
      },
    },
  },
  close: {
    position: 'absolute',
    top: '50%',
    right: ['0.75rem', '1rem'],
    transform: 'translateY(-50%)',
    zIndex: 1,
    width: 'auto',
    height: 'auto',
    svg: {
      fontSize: ['0.875rem', '1rem'],
    },
  },
  [BannerVariant.Default]: {
    variant: 'banner.base',
    backgroundColor: 'sunGlow',
    color: 'ebonyClay',
  },
  [BannerVariant.Info]: {
    variant: 'banner.base',
    backgroundColor: 'havelockBlue',
    color: 'white',
  },
};
