import React from 'react';
import { Box } from 'theme-ui';
import IconButton from 'components/atoms/Buttons/IconButton';
import { IconCross } from 'components/styles/Icons';
import useToggle from 'hooks/useToggle';
import { BannerProps } from './types';
import { BannerVariant } from './consts';
import { StyledContentWrapper } from './styles';

const Banner: React.FC<BannerProps> = ({
  children,
  variant = BannerVariant.Default,
  withoutCloseButton = false,
}) => {
  const [show, { toggleOff }] = useToggle({
    defaultToggleState: true,
    toggleOnRouteChange: false,
  });

  return show ? (
    <Box variant={`banner.${variant}`}>
      <Box variant="banner.content">
        <StyledContentWrapper>{children}</StyledContentWrapper>
      </Box>

      {!withoutCloseButton && (
        <IconButton
          aria-label="Hide announcement banner"
          variant="banner.close"
          onClick={toggleOff}
        >
          <IconCross />
        </IconButton>
      )}
    </Box>
  ) : null;
};

export default Banner;
