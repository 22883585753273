import { ThemeUICSSObject } from 'theme-ui';

export default {
  forms: {
    my: '1.5rem',
  },
  details: {
    p: {
      variant: 'text.text',
      fontFamily: 'secondary',
      wordBreak: 'break-word',
    },
    strong: {
      fontWeight: 'semiBold',
    },
  },
  text: {
    fontSize: '1rem',
    fontWeight: 'semiBold',
    fontFamily: 'secondary',
    mb: '0.375rem',
    borderBottom: '2px solid',
    borderColor: 'fuelYellow',
  },
  status: {
    textTransform: 'uppercase' as ThemeUICSSObject['textTransform'],
  },
  required: {
    textAlign: 'center' as ThemeUICSSObject['textAlign'],
    variant: 'form.required',
  },
  '.react-autosuggest__suggestions-container': {
    fontSize: '0.875rem',
    my: '0.5rem',
  },
  '.react-autosuggest__suggestions-container.react-autosuggest__suggestions-container--open':
    {
      maxHeight: '10rem',
      maxWidth: '100%',
      overflow: 'auto',
    },
  '.react-autosuggest__suggestions-list': {
    display: 'flex',
    flexDirection: 'column',
  },
  '.react-autosuggest__suggestion': {
    p: '0.375rem',
    cursor: 'pointer',
    variant: 'transitions.default',
    '&:hover': {
      backgroundColor: 'mercury',
    },
  },
};
