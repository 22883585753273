import React, { Fragment } from 'react';
import { Box, Button, Flex } from 'theme-ui';
import { useRouter } from 'next/router';

import Link from 'components/atoms/Link';
import Image from 'components/atoms/Image';
import { LinkVariant } from 'components/atoms/Link/types';
import useToggle from 'hooks/useToggle';
import routes from 'setup/consts/routes';
import useIsDesktopDevice from 'hooks/useIsDesktopDevice';
import { SelectLocationProps } from './types';
import {
  StyledChevron,
  StyledGlobe,
  StyledLocationDropdown,
  StyledLocationMobileWrapper,
  StyledLocationWrapper,
} from './styles';

const SelectLocation: React.FC<SelectLocationProps> = ({
  items,
  toggleRef,
}) => {
  const isDesktopDevice = useIsDesktopDevice();
  const router = useRouter();
  const selectedDropdownItem = items.find(
    ({ locale }) => locale === router.locale,
  );

  const dropdownItems = items.filter(({ locale }) => locale !== router.locale);

  const [isOpen, { toggleOn, registerContainerRef: selectLocationRef }] =
    useToggle();

  const dropdownComponent = (
    <Box as="ul" variant="selectLocation.dropdownList">
      {dropdownItems.map(({ key, label, href, src, alt, locale }) => (
        <Box {...{ key }} as="li" variant="selectLocation.dropdownItem">
          <Link variant={LinkVariant.Default} {...{ href, locale }}>
            {label}

            <Box variant="selectLocation.image">
              <Image {...{ src, alt }} width={18} height={12} lazy={false} />
            </Box>
          </Link>
        </Box>
      ))}
    </Box>
  );

  toggleRef?.(toggleOn);

  const linkComponent = (
    <Link
      variant={LinkVariant.Default}
      href={selectedDropdownItem?.href || routes.home}
    >
      {selectedDropdownItem?.label}

      {selectedDropdownItem?.src && (
        <Box variant="selectLocation.image">
          <Image
            src={selectedDropdownItem.src}
            alt={selectedDropdownItem?.alt}
            width={18}
            height={12}
            lazy={false}
          />
        </Box>
      )}
    </Link>
  );

  return (
    <Flex variant="selectLocation">
      {isDesktopDevice ? (
        <StyledLocationDropdown variant="selectLocation.dropdown">
          <StyledGlobe />
          {linkComponent}
          <StyledChevron {...{ isOpen }} />

          <StyledLocationWrapper
            className="location-wrapper"
            variant="selectLocation.dropdownWrapper"
          >
            {dropdownComponent}
          </StyledLocationWrapper>
        </StyledLocationDropdown>
      ) : (
        <Fragment>
          {isOpen ? (
            <Flex variant="selectLocation.dropdown">
              <StyledGlobe />
              {linkComponent}
              <StyledChevron {...{ isOpen }} />

              <StyledLocationMobileWrapper
                ref={selectLocationRef}
                variant="selectLocation.dropdownWrapper"
              >
                {dropdownComponent}
              </StyledLocationMobileWrapper>
            </Flex>
          ) : (
            <Flex variant="selectLocation.dropdown" onClick={toggleOn}>
              <Button
                id="locale-select-button"
                type="button"
                variant="selectLocation.dropdown.buttonLink"
              >
                <StyledGlobe />
                {selectedDropdownItem?.label}

                <Box variant="selectLocation.image">
                  {selectedDropdownItem?.src && (
                    <Image
                      src={selectedDropdownItem.src}
                      alt={selectedDropdownItem.alt}
                      width={18}
                      height={12}
                      lazy={false}
                    />
                  )}
                </Box>

                <StyledChevron {...{ isOpen }} />
              </Button>
            </Flex>
          )}
        </Fragment>
      )}
    </Flex>
  );
};

export default SelectLocation;
