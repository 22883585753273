import React, { PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Flex } from 'theme-ui';

import media from 'setup/MediaQueries';
import { IconArrowDown, IconGlobe } from 'components/styles/Icons';
import { StyledChevronProps } from './types';

const locationWrapperStyle = () =>
  css`
    position: absolute;
    top: 100%;
    left: -1px;
    width: calc(100% + 2px);
    top: calc(100% - 0.5rem);
  `;

export const StyledChevron = styled(
  ({ isOpen: _, ...props }: PropsWithChildren<StyledChevronProps>) => (
    <IconArrowDown {...props} />
  ),
)<StyledChevronProps>`
  font-size: 0.6rem;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0)')};
  ${({ theme }) => theme.transitions.default};
`;

export const StyledGlobe = styled(IconGlobe)`
  font-size: 1em;
  font-size: 0.75rem;
  margin-right: 0.5rem;
`;

export const StyledLocationMobileWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.white};
  ${locationWrapperStyle}
`;

export const StyledLocationWrapper = styled(Flex)`
  transition: transform 0.25s ease-out, opacity 0.125s ease-out;
  max-height: 0;
  transform: translate3d(0, 0.625rem, 0);
  opacity: 0;
  pointer-events: none;
  background-color: ${({ theme }) => theme.colors.white};
  ${locationWrapperStyle}
`;

export const StyledLocationDropdown = styled(Flex)`
  align-items: center;
  cursor: pointer;

  &:hover {
    .location-wrapper {
      transform: translate3d(0, 0, 0);
      opacity: 1;
      max-height: 999px;
      pointer-events: auto;
    }

    @media ${media.tablet} {
      ${StyledChevron} {
        transform: rotate(180deg);
      }
    }
  }
`;
