import { ThemeUICSSObject } from 'theme-ui';

export default {
  '&[disabled]': {
    opacity: 0.6,
    pointerEvents: 'none' as ThemeUICSSObject['pointerEvents'],
  },
  modes: {
    default: {
      fontSize: '1rem',
    },
    surveyQuestion: {
      minHeight: '2.5rem',
    },
  },
};
