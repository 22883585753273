import React, { Fragment, ReactElement } from 'react';
import { ThemeProvider } from '@emotion/react';
import NextApp from 'next/app';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Hydrate } from 'react-query/hydration';
import Head from 'next/head';

import theme from 'setup/theme';
import GlobalStyles from 'setup/GlobalStyles';
import { getConfig } from 'utils/config';
import urljoin from 'url-join';
import Layout from 'components/styles/Layout';
import {
  CITIES_PROPS_PAGE_PROPS_KEY,
  COINS_CONFIG_PROPS_PAGE_PROPS_KEY,
  FOOTER_PROPS_PAGE_PROPS_KEY,
  HEADER_PROPS_PAGE_PROPS_KEY,
} from 'utils/prismic/consts';
import { PageFooterProps, PageHeaderProps } from 'utils/prismic/types';
import { FooterProps } from 'components/organisms/Footer/types';
import { scrollToHash } from 'utils/browser';
import { HeaderProps } from 'components/organisms/Header/types';
import RichTextRenderer from 'components/PageSectionsRenderer/RichTextRenderer';
import { CitySelectItem } from 'components/organisms/FindATM/types';
import GlobalConfigContextValue from 'contexts/GlobalConfigContext';
import AlertsContextProvider from 'contexts/AlertsContext';
import { CoinsConfig } from 'utils/api/types';
import { ensureCorrectHreflangTrailingSlash } from 'components/HrefLangs/utils';
import { makeOrganizationSchema, makeReactQueryClient } from './utils';
import { LOCALES } from '../../../../config/next/languages';

interface AppProps {
  pageProps: unknown;
}

class LocalCoinApp extends NextApp<AppProps> {
  private queryClient: QueryClient | undefined;

  private previousLocale: string | undefined;

  get headerProps(): Pick<
    HeaderProps,
    | 'navItems'
    | 'newsletterBannerContent'
    | 'newsletterPillContent'
    | 'hostAtmLink'
    | 'hostAtmLinkLabel'
  > {
    const {
      navItems,
      topBannerContent,
      topBannerPillContent,
      hostAtmLink,
      hostAtmLinkLabel,
    } = (this.props.pageProps[HEADER_PROPS_PAGE_PROPS_KEY] || {
      navItems: [],
    }) as PageHeaderProps;

    return {
      navItems,
      newsletterBannerContent: topBannerContent ? (
        <RichTextRenderer content={topBannerContent} />
      ) : undefined,
      newsletterPillContent: topBannerPillContent,
      hostAtmLink,
      hostAtmLinkLabel,
    };
  }

  get footerProps(): Omit<FooterProps, 'items'> {
    const { columns, disclaimer, copy } = (this.props.pageProps[
      FOOTER_PROPS_PAGE_PROPS_KEY
    ] || { columns: [] }) as PageFooterProps;

    return {
      columns: columns.map(({ content, ...column }) => ({
        ...column,
        content: content ? <RichTextRenderer {...{ content }} /> : undefined,
      })),
      disclaimer,
      copy,
    };
  }

  get citiesConfig(): CitySelectItem[] {
    const citiesList = this.props.pageProps[CITIES_PROPS_PAGE_PROPS_KEY] || [];

    return citiesList;
  }

  get coinsConfig(): CoinsConfig[] {
    return this.props.pageProps[COINS_CONFIG_PROPS_PAGE_PROPS_KEY] || [];
  }

  scrollToCurrentHash = (): void => {
    const [, hash] = this.props.router.asPath.split('#');

    scrollToHash(hash, false);
  };

  componentDidMount(): void {
    const { gtmId } = getConfig().publicRuntimeConfig;

    if (gtmId) {
      import('react-gtm-module').then(({ default: TagManager }) => {
        window.tagManager = TagManager;
        TagManager.initialize({ gtmId });
      });
    }

    this.scrollToCurrentHash();

    this.props.router.events.on(
      'routeChangeComplete',
      this.scrollToCurrentHash,
    );
  }

  render(): ReactElement<unknown> {
    const { Component, pageProps, router } = this.props;
    const { appDomain } = getConfig().publicRuntimeConfig;
    const [pathname] = router.asPath.split('#');
    const { asPath, defaultLocale, locale: currentLocale } = router;

    if (!this.queryClient || currentLocale !== this.previousLocale) {
      this.queryClient = makeReactQueryClient(currentLocale || LOCALES.ca);
      this.previousLocale = currentLocale;
    }

    const href = ensureCorrectHreflangTrailingSlash(
      urljoin(
        appDomain,
        currentLocale === defaultLocale ? '' : `${currentLocale}/`,
        asPath,
      ),
    );

    return (
      <Fragment>
        <Head>
          {router.isPreview ? (
            <script
              async
              defer
              src={getConfig().publicRuntimeConfig.prismicPreviewJsUrl}
            />
          ) : null}
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link
            rel="preconnect"
            href="https://fonts.gstatic.com"
            crossOrigin="anonymous"
          />
          <link
            href="https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap"
            rel="stylesheet"
          />
          <link rel="canonical" {...{ href }} />
          <meta
            name="og:image"
            content={urljoin(appDomain, '/assets/images/machine-coins.png')}
          />
          <script
            type="application/ld+json"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(
                makeOrganizationSchema(
                  currentLocale || LOCALES.ca,
                  defaultLocale || LOCALES.ca,
                ),
              ),
            }}
          />
        </Head>
        <QueryClientProvider client={this.queryClient}>
          <GlobalConfigContextValue
            citiesConfig={this.citiesConfig}
            coinsConfig={this.coinsConfig}
          >
            <AlertsContextProvider>
              <Hydrate state={pageProps.dehydratedState}>
                <ThemeProvider {...{ theme }}>
                  <GlobalStyles />
                  <Layout
                    {...this.headerProps}
                    footerProps={this.footerProps}
                    isNavigationHidden={
                      this.props.pageProps.pageData?.isNavigationHidden
                    }
                  >
                    <Component key={pathname} {...pageProps} />
                  </Layout>
                </ThemeProvider>
              </Hydrate>
            </AlertsContextProvider>
          </GlobalConfigContextValue>
        </QueryClientProvider>
      </Fragment>
    );
  }
}

export default LocalCoinApp;
