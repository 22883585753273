import { ThemeUICSSObject } from 'theme-ui';
import withBg from './HeroWithBg/theme';
import simple from './HeroSimple/theme';

export default {
  grid: {
    position: 'relative' as ThemeUICSSObject['position'],
    zIndex: 1,
    alignItems: 'center',
    textAlign: ['center', '', 'left'],
    gridColumnGap: '2rem',
    gridRowGap: '2rem',
  },
  modes: {
    default: {
      gridTemplateColumns: ['1fr', '', '1fr 27.75rem'],
    },
    wide: {
      gridTemplateColumns: '1fr',
    },
    half: {
      gridTemplateColumns: ['1fr', '', 'repeat(2, 1fr)'],
    },
    singleCentered: {
      gridTemplateColumns: '1fr',
    },
  },
  title: {
    variant: 'text.heading0',
    lineHeight: 1.1,
  },
  description: {
    mt: '0.75rem',
    variant: 'text.heading6',
    fontWeight: 'regular',
  },
  subtitle: {
    mt: '1rem',
    variant: 'text.heading2',
    fontWeight: 'regular',
  },
  content: {
    width: '100%',
    mx: 'auto',
  },
  form: {
    justifyContent: 'center',
  },
  actions: {
    mt: '1.5rem',
  },
  buttons: {
    justifyContent: ['center', '', 'flex-start'],
    flexDirection: ['column', 'row'],
    'button, a': {
      '&:not(:last-child)': {
        mb: ['0.75rem', 0],
        mr: ['', '0.75rem'],
      },
    },
  },
  buttonsCentered: {
    variant: 'hero.buttons',
    justifyContent: 'center',
  },
  buttonsRight: {
    variant: 'hero.buttons',
    justifyContent: 'end',
  },
  wrapper: {
    maxWidth: ['', '', '34rem'],
  },
  wrapperAlt: {
    maxWidth: ['', '', '43rem'],
    mx: 'auto',
    textAlign: 'center' as ThemeUICSSObject['textAlign'],
  },
  withBg,
  simple,
  withImage: {
    image: {
      textAlign: 'center' as ThemeUICSSObject['textAlign'],
      mb: ['', '', '-6.25rem'],
    },
    imageMobile: {
      display: ['', '', 'none'],
      textAlign: 'center' as ThemeUICSSObject['textAlign'],
    },
  },
};
