import text from 'components/styles/Typography/theme';
import buttons from 'components/atoms/Buttons/theme';
import blankAnchor from 'components/atoms/BlankAnchor/theme';
import loader from 'components/atoms/Loader/theme';
import images from 'components/atoms/Image/theme';
import hamburger from 'components/atoms/Hamburger/theme';
import currencyLabel from 'components/atoms/CurrencyLabel/theme';
import message from 'components/atoms/Message/theme';
import forms from 'components/atoms/Fields/theme';
import links from 'components/atoms/Link/theme';
import locationMap from 'components/atoms/LocationMap/theme';
import cryptoCurrency from 'components/atoms/CryptoCurrency/theme';
import breadcrumbs from 'components/atoms/Breadcrumbs/theme';
import badges from 'components/atoms/Badge/theme';
import accordion from 'components/molecules/Accordion/theme';
import video from 'components/molecules/Videos/theme';
import wallet from 'components/molecules/Wallet/theme';
import tabs from 'components/molecules/Tabs/theme';
import newsletter from 'components/molecules/Newsletter/theme';
import numberInfo from 'components/molecules/NumberInfo/theme';
import productsList from 'components/molecules/ProductsList/theme';
import banner from 'components/molecules/Banner/theme';
import feature from 'components/molecules/Feature/theme';
import nearestAtm from 'components/molecules/NearestATM/theme';
import navigation from 'components/molecules/Navigation/theme';
import cities from 'components/molecules/Cities/theme';
import currencyLabelsList from 'components/molecules/CurrencyLabelsList/theme';
import atmLocation from 'components/molecules/ATMLocation/theme';
import selectLocation from 'components/molecules/SelectLocation/theme';
import featuresList from 'components/organisms/FeaturesList/theme';
import hero from 'components/organisms/Heros/theme';
import logos from 'components/organisms/Logos/theme';
import walletsList from 'components/organisms/WalletsList/theme';
import cta from 'components/organisms/CTA/theme';
import form from 'components/organisms/Forms/theme';
import aboutUs from 'components/organisms/AboutUs/theme';
import header from 'components/organisms/Header/theme';
import cms from 'components/organisms/CMS/theme';
import findAtm from 'components/organisms/FindATM/theme';
import footer from 'components/organisms/Footer/theme';
import nearestAtmsList from 'components/organisms/NearestATMsList/theme';
import columnsContent from 'components/organisms/ColumnsContent/theme';
import numberInfoList from 'components/organisms/NumberInfoList/theme';
import locationDetails from 'components/organisms/LocationDetails/theme';
import partnerships from 'components/organisms/Partnerships/theme';
import textWithCta from 'components/organisms/TextWithCta/theme';
import dataTable from 'components/organisms/DataTable/theme';
import atmDetails from 'components/organisms/ATMDetails/theme';
import twitterFeed from 'components/organisms/TwitterFeed/theme';
import news from 'components/organisms/News/theme';
import newsFeed from 'components/organisms/NewsFeed/theme';
import atmLocationListing from 'components/organisms/ATMLocationListing/theme';
import section from 'components/sections/theme';
import faq from 'components/sections/Faq/theme';
import textMedia from 'components/sections/TextMedia/theme';
import stepSection from 'components/sections/StepSection/theme';
import videoSection from 'components/sections/VideoSection/theme';
import newsTeaserSection from 'components/sections/NewsTeaserSection/theme';
import newsFeedSection from 'components/sections/NewsFeedSection/theme';
import feeOptionsSection from 'components/sections/FeeOptionsSection/theme';
import logosListSection from 'components/sections/LogosListSection/theme';
import infoBox from 'components/atoms/InfoBox/theme';
import stepsWithIconSection from 'components/sections/StepsWithIconSection/theme';

const theme = {
  colors: {
    background: '#FFF',
    white: '#FFF',
    black: '#000',
    gray: '#ccc',
    saffronMango: '#fcc95c',
    texasRose: '#FFB153',
    buttercup: '#F4AA0A',
    treePoppy: '#F7931A',
    springWood: '#F9F7F2',
    alabaster: '#F7F7F7',
    mercury: '#E6E6E6',
    silver: '#BFBFBF',
    dustyGray: '#999999',
    abbey: '#58595B',
    zircon: '#F4F9FF',
    aliceblue: '#ebf9fc',
    lightsteelblue: '#98c6cd',
    lochmara: '#087DC2',
    havelockBlue: '#5899DB',
    cornflowerBlue: '#637EEA',
    lightseagreen: '#30a0ac',
    charade: '#2C2D38',
    shuttleGray: '#575C6B',
    malachite: '#12BC48',
    goldenTainoi: '#FFCC4D',
    candleLight: '#FCCB0E',
    text: '#3A3A3A',
    error: '#FB2121',
    thunder: '#231f20',
    trinidad: '#ed5a00',
    fuelYellow: '#F2A33B',
    ebonyClay: '#262945',
    sunGlow: '#FFBD30',
  },
  breakpoints: ['768px', '1024px', '1440px'],
  fonts: {
    body: `'Inter', sans-serif`,
    secondary: `'Inter', sans-serif;`,
  },
  fontWeights: {
    light: 300,
    regular: 400,
    semiBold: 600,
    bold: 700,
  },
  transitions: {
    default: {
      transition: '250ms ease-in-out',
    },
  },
  radii: {
    small: '0.25em',
    medium: '2.0em',
  },
  sizes: {
    inner: '68.75rem',
    wideInner: '85.625rem',
  },
  shadows: {
    header: '0 0 7px rgb(0 0 0 / 10%)',
    nearestAtm:
      '0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%)',
    banner: '0 5px 8px 2px rgb(0 0 0 / 10%)',
    locationDetails: '0.625em 0.625em 0.3125em #ccc',
    transactionChecker: '0.3125em 0.3125em 0.625em 0.3125em rgb(0 0 0 / 50%)',
    selectLocationDropdown: '0 10px 10px rgb(0 0 0 / 15%)',
    subMenu: '0 1px 20px rgb(0 0 0 / 15%)',
    buyBitcoinForm: '2px 2px 4px 2px rgb(0 0 0 / 23%)',
  },
  space: {
    zero: 0,
    default: '1.25rem',
    xs: '1rem',
    s: '2rem',
    m: '3rem',
    l: '4rem',
    xl: '5rem',
  },
  zIndices: {
    header: 12,
    stickyHeader: 12,
    selectLocation: 10,
  },
  styles: {
    hr: {
      borderColor: 'mercury',
    },
  },
  layout: {
    inner: {
      maxWidth: 'inner',
      pl: 'default',
      pr: 'default',
    },
    innerWide: {
      maxWidth: 'wideInner',
      pl: 'default',
      pr: 'default',
    },
  },
  gradient: {
    backgroundColor: 'white',
    backgroundImage: 'linear-gradient( 180deg, #ffffff 0%, #f6f9ff 100%)',
  },
  text,
  buttons,
  blankAnchor,
  loader,
  images,
  hamburger,
  currencyLabel,
  message,
  forms,
  links,
  locationMap,
  cryptoCurrency,
  breadcrumbs,
  badges,
  accordion,
  video,
  wallet,
  tabs,
  newsletter,
  numberInfo,
  productsList,
  banner,
  feature,
  nearestAtm,
  navigation,
  cities,
  currencyLabelsList,
  atmLocation,
  selectLocation,
  featuresList,
  hero,
  logos,
  walletsList,
  cta,
  form,
  aboutUs,
  header,
  cms,
  findAtm,
  footer,
  nearestAtmsList,
  columnsContent,
  numberInfoList,
  locationDetails,
  partnerships,
  textWithCta,
  dataTable,
  atmDetails,
  twitterFeed,
  news,
  newsFeed,
  atmLocationListing,
  section,
  faq,
  textMedia,
  stepSection,
  videoSection,
  newsTeaserSection,
  newsFeedSection,
  feeOptionsSection,
  logosListSection,
  infoBox,
  stepsWithIconSection,
};

export default theme;

export type Theme = typeof theme;
