const apiRoutes = {
  locations: '/locations/',
  locationRates: (locationId: string): string =>
    `/locations/${locationId}/rates/`,
  locationDetails: (locationId: string): string => `/locations/${locationId}/`,
  transactionDetailsById: (transactionId: string): string =>
    `/transactions/${transactionId}/`,
  transactionDetailsByDetails: '/transactions/',
  contactHostATM: '/contact/host-atm/',
  contact: '/contact/',
  otc: '/otc/',
  newsletter: '/newsletter/',
  sellCryptoLocations: '/sell-crypto/available-locations',
  sellCryptoSendVerificationSms: '/sell-crypto/send-verification-sms',
  sellCryptoVerifyPin: '/sell-crypto/verify-pin',
  sellCryptoLocationDetails: (locationId: string): string =>
    `/sell-crypto/${locationId}`,
  sellCryptoLocationRates: (locationId: string): string =>
    `/sell-crypto/${locationId}/rates`,
  sellCryptoBillDistribution: (locationId: string): string =>
    `/sell-crypto/${locationId}/bill-distribution`,
  sellCryptoOrderDetails: '/sell-crypto/order',
  sellCryptoRequestReedemVerification:
    '/sell-crypto/order/request-redeem-verification',
  sellCryptoVerifyPinRedeem: '/sell-crypto/order/verify-pin-redeem',
  submitSurvey: '/survey/submit',
  submitSurveyDirectFeedback: '/survey/submit/direct-feedback',
  alerts: '/alerts/',
};

export default apiRoutes;
