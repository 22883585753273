import React, { useContext } from 'react';
import { GlobalConfigContextValue } from './types';

const GlobalConfigContext = React.createContext<GlobalConfigContextValue>({
  citiesConfig: [],
  coinsConfig: [],
});

export const useGlobalConfigContext = (): GlobalConfigContextValue =>
  useContext(GlobalConfigContext);

const GlobalConfigContextProvider: React.FC<GlobalConfigContextValue> = ({
  children,
  ...value
}) => (
  <GlobalConfigContext.Provider {...{ value }}>
    {children}
  </GlobalConfigContext.Provider>
);

export default GlobalConfigContextProvider;
