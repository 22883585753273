const LOCALES = {
  ca: 'ca',
  fr: 'fr',
  au: 'au',
  nz: 'nz',
};

const i18n = {
  locales: [LOCALES.ca, LOCALES.fr, LOCALES.au, LOCALES.nz],
  defaultLocale: LOCALES.ca,
  localeDetection: false,
};

module.exports = {
  LOCALES,
  i18n,
};
