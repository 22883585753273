import React, { Fragment } from 'react';
import { Box, Flex, Text } from 'theme-ui';

import LazyHydrate from 'components/LazyHydrate';
import Inner from 'components/styles/Inner';
import { IconLogo } from 'components/styles/Icons';
import routes from 'setup/consts/routes';
import Link from 'components/atoms/Link';
import Hamburger from 'components/atoms/Hamburger';
import useToggle from 'hooks/useToggle';
import { HeaderProps } from './types';
import Nav from './partials/Nav';
import MobileButton from './partials/MobileButton';
import { StyledHeader, StyledMainNav, StyledStickyHeader } from './styles';
import { useStickyHeader } from './hooks';

const Header: React.FC<HeaderProps> = ({
  navItems,
  hostAtmLink,
  hostAtmLinkLabel,
}) => {
  const [isMenuOpen, { toggle }] = useToggle();

  useStickyHeader();

  const headerMainComponent = (
    <Box variant="header.content">
      <Inner>
        <Flex variant="header.main">
          <Box variant="header.logo">
            <Link href={routes.home}>
              <IconLogo />
              <Text as="span" variant="hidden">
                Localcoin
              </Text>
            </Link>
          </Box>
          <Box variant="header.navWrapper">
            <Nav
              {...{
                hostAtmLink,
                hostAtmLinkLabel,
              }}
              items={navItems}
            />
          </Box>
          <Box variant="header.mobileNavWrapper">
            <MobileButton aria-label="Open menu" type="button" onClick={toggle}>
              <Hamburger isOpen={isMenuOpen} />
            </MobileButton>
            <StyledMainNav isOpen={isMenuOpen}>
              <Nav
                {...{
                  hostAtmLink,
                  hostAtmLinkLabel,
                }}
                items={navItems}
              />
            </StyledMainNav>
          </Box>
        </Flex>
      </Inner>
    </Box>
  );

  return (
    <LazyHydrate
      noWrapper="header"
      on={['scroll', 'mouseover']}
      getEventTarget={() => window}
      didHydrate={() => {
        // eslint-disable-next-line no-console
        console.log('hydrated header');
      }}
    >
      <Fragment>
        <StyledStickyHeader
          variant="header"
          sx={{
            boxShadow: 'header',
          }}
        >
          {headerMainComponent}
        </StyledStickyHeader>
        <StyledHeader variant="header">{headerMainComponent}</StyledHeader>
      </Fragment>
    </LazyHydrate>
  );
};

export default Header;
