import apiRoutes from 'setup/consts/apiRoutes';
import { ALERTS_LOCALE_QUERY_PARAM } from './consts';

export const makeAlertsUrl = (locale: string | undefined): string => {
  if (!locale) {
    return apiRoutes.alerts;
  }

  const searchParams = new URLSearchParams();

  searchParams.append(ALERTS_LOCALE_QUERY_PARAM, locale);

  return `${apiRoutes.alerts}?${searchParams.toString()}`;
};
