export default {
  mx: ['', '', '-1.25rem', '-3rem'],
  item: {
    p: ['3.125rem 1rem', '3.125rem'],
    '&:nth-child(even)': {
      backgroundColor: 'alabaster',
    },
  },
  heading: {
    marginBotton: '2rem',
    fontWeight: 600,
    textAlign: 'center',
  },
};
